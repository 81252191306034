import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';

const faqs = [
  {
    question: "How secure is MyEcho?",
    answer: "MyEcho uses state-of-the-art encryption to protect your data. Your messages and information are securely stored and can only be accessed when the specified conditions are met."
  },
  {
    question: "What happens if I want to change my messages or triggers?",
    answer: "You can easily update your messages and modify your triggers at any time through your MyEcho dashboard. Changes are saved and encrypted immediately."
  },
  {
    question: "Can I use MyEcho for business purposes?",
    answer: "Absolutely! MyEcho offers business plans that cater to the needs of companies, including features for business continuity and succession planning."
  },
  {
    question: "How does MyEcho verify my passing?",
    answer: "MyEcho uses a combination of methods, including periodic check-ins, trusted contact verification, and other customizable triggers to ensure the authenticity of the activation event."
  },
  {
    question: "What types of messages can I schedule?",
    answer: "MyEcho supports various types of messages, including emails, text messages, social media posts, and even pre-recorded video messages, depending on your chosen plan."
  }
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-900">{question}</span>
        {isOpen ? (
          <MinusIcon className="h-5 w-5 text-blue-500" />
        ) : (
          <PlusIcon className="h-5 w-5 text-blue-500" />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="mt-2 text-gray-600">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = () => (
  <section id="faq" className="py-20 bg-white">
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Frequently Asked Questions
        </h2>
        <p className="mt-4 text-xl text-gray-500">
          Can't find the answer you're looking for? Reach out to our customer support team.
        </p>
      </div>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <FAQItem key={index} {...faq} />
        ))}
      </div>
    </div>
  </section>
);

export default FAQ;