import React from 'react';
import { motion } from 'framer-motion';

const testimonials = [
  {
    quote: "MyEcho gave me peace of mind knowing that my final messages will reach my loved ones.",
    author: "Sarah J.",
    role: "Mother of two"
  },
  {
    quote: "As a business owner, MyEcho ensures my company's future is secure, even if something happens to me.",
    author: "Michael R.",
    role: "Entrepreneur"
  },
  {
    quote: "The ease of use and security features of MyEcho are unparalleled. Highly recommended!",
    author: "Emily T.",
    role: "Tech enthusiast"
  }
];

const StarRating = () => (
  <div className="flex text-yellow-400 mb-2">
    {[...Array(5)].map((_, i) => (
      <svg key={i} className="w-5 h-5 fill-current" viewBox="0 0 24 24">
        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
      </svg>
    ))}
  </div>
);

const TestimonialCard = ({ quote, author, role }) => (
  <motion.div
    className="bg-white p-8 rounded-lg shadow-lg"
    whileHover={{ scale: 1.03 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <StarRating />
    <p className="text-gray-600 mb-4">{quote}</p>
    <div className="font-semibold">{author}</div>
    <div className="text-gray-500 text-sm">{role}</div>
  </motion.div>
);

const Testimonials = () => (
  <section className="py-20 bg-blue-50">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          What Our Users Say
        </h2>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Hear from people who have secured their digital legacy with MyEcho
        </p>
      </div>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} {...testimonial} />
        ))}
      </div>
    </div>
  </section>
);

export default Testimonials;