import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">MyEcho</Link>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-8">
            <Link to="/features" className="text-gray-500 hover:text-blue-600 px-3 py-2 text-sm font-medium">
              Features
            </Link>
            <Link to="/pricing" className="text-gray-500 hover:text-blue-600 px-3 py-2 text-sm font-medium">
              Pricing
            </Link>
            <Link to="/login" className="text-gray-500 hover:text-blue-600 px-3 py-2 text-sm font-medium">
              Login
            </Link>
            <Link to="/signup" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200">
              Start Now
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/features" className="text-gray-500 hover:text-blue-600 block px-3 py-2 text-base font-medium">
              Features
            </Link>
            <Link to="/pricing" className="text-gray-500 hover:text-blue-600 block px-3 py-2 text-base font-medium">
              Pricing
            </Link>
            <Link to="/login" className="text-gray-500 hover:text-blue-600 block px-3 py-2 text-base font-medium">
              Login
            </Link>
            <Link to="/signup" className="text-blue-700 bg-blue-100 hover:bg-blue-200 block px-3 py-2 text-base font-medium">
              Start Now
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;