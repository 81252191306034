import React from 'react';
import { motion } from 'framer-motion';
import { LockClosedIcon, ClockIcon, BellIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    className="bg-white p-6 rounded-lg shadow-lg"
    whileHover={{ y: -5 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex items-center mb-4">
      <div className="flex-shrink-0 mr-4">
        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-100">
          <Icon className="w-6 h-6 text-blue-600" />
        </div>
      </div>
      <h3 className="text-xl font-semibold">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const KeyFeatures = () => (
  <section className="py-20 bg-blue-50">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Key Features
        </h2>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
          Discover how MyEcho helps you maintain your digital presence beyond life.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <FeatureCard
          icon={LockClosedIcon}
          title="Secure Storage"
          description="Your messages and data are encrypted, ensuring complete privacy until the moment they're needed."
        />
        <FeatureCard
          icon={ClockIcon}
          title="Scheduled Messages"
          description="Set up emails, texts, and social media posts to be sent at specific future dates or upon certain conditions."
        />
        <FeatureCard
          icon={BellIcon}
          title="Multiple Triggers"
          description="Choose from various activation methods, including periodic check-ins and consensus verification."
        />
        <FeatureCard
          icon={UserGroupIcon}
          title="Trusted Contacts"
          description="Designate trusted individuals to help manage and verify the activation of your digital legacy."
        />
      </div>
    </div>
  </section>
);

export default KeyFeatures;