// src/pages/dashboard/TriggersPage.jsx
import React, { useState } from 'react';
import TriggerCard from '../../components/dashboard/triggers/TriggerCard';

const TriggersPage = () => {
  const [triggers, setTriggers] = useState({
    'Recurring Check-in': {
      frequency: 'weekly',
      notificationMethod: 'email',
      gracePeriod: '1week',
    },
    'Trusted Consensus': {
      requiredConfirmations: 3,
      trustedContacts: [], // Initialize as an empty array
      finalVerificationPeriod: '3days',
    },
  });

  const handleSave = (type, settings) => {
    setTriggers(prev => ({ ...prev, [type]: settings }));
    // Here you would typically make an API call to save the settings
    console.log(`Saving ${type} settings:`, settings);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">Triggers</h1>
          <p className="text-gray-600">
            Triggers determine when your messages will be sent. You can set up multiple triggers for added security.
            Configure your preferred methods below and ensure your digital legacy is managed according to your wishes.
          </p>
        </div>
      </div>

      {Object.entries(triggers).map(([type, settings]) => (
        <TriggerCard
          key={type}
          type={type}
          settings={settings}
          onSave={handleSave}
        />
      ))}
    </div>
  );
};

export default TriggersPage;