import React from 'react';
import MessageCard from './MessageCard';

const MessageList = ({ messages, onView, onEdit, onDelete }) => (
    <div className="space-y-4">
      {messages.map((message) => (
        <MessageCard
          key={message.id}
          message={message}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </div>
  );

export default MessageList;