import React from 'react';
import { motion } from 'framer-motion';
import { UserIcon, PencilSquareIcon, ClockIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

const steps = [
  {
    title: "Sign Up",
    description: "Create your secure MyEcho account and set up your profile.",
    icon: UserIcon,
  },
  {
    title: "Compose Messages",
    description: "Write emails, texts, or social media posts to be sent in the future.",
    icon: PencilSquareIcon,
  },
  {
    title: "Set Triggers",
    description: "Choose how and when your messages will be activated and sent.",
    icon: ClockIcon,
  },
  {
    title: "Live with Peace of Mind",
    description: "Rest easy knowing your digital legacy is secure and ready when needed.",
    icon: ShieldCheckIcon,
  }
];

const HowItWorks = () => (
  <section id="how-it-works" className="py-20 bg-gray-50">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          How It Works
        </h2>
        <p className="mt-4 max-w-2xl text-lg text-gray-600 mx-auto">
          Four simple steps to secure your digital legacy
        </p>
      </div>

      <div className="relative">
        {/* Connecting line */}
        <div className="absolute left-4 top-0 bottom-0 w-0.5 md:w-full md:h-0.5 bg-blue-200 md:left-0 md:right-0 md:top-5 md:bottom-auto" aria-hidden="true"></div>

        <div className="relative z-10 md:flex md:justify-between">
          {steps.map((step, index) => (
            <motion.div 
              key={index}
              className="mb-12 md:mb-0 md:w-1/4 flex md:flex-col items-start md:items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <div className="flex-shrink-0 mr-4 md:mr-0 md:mb-4">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-500 text-white shadow-lg border-4 border-white z-10">
                  <step.icon className="w-5 h-5" aria-hidden="true" />
                </div>
              </div>
              <div className="md:text-center">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{step.title}</h3>
                <p className="text-gray-600 text-sm">{step.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default HowItWorks;