import React, { useState, useEffect, useRef } from 'react';
import { XMarkIcon, PaperClipIcon, EyeIcon, PencilIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDropzone } from 'react-dropzone';

const MessageEditor = ({ message, mode, onSave, onClose, userEmails }) => {
  const [editedMessage, setEditedMessage] = useState({
    ...message,
    type: message.type || 'email'
  });
  const [files, setFiles] = useState([]);
  const [viewMode, setViewMode] = useState(mode === 'view');
  const [showBcc, setShowBcc] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [bccRecipients, setBccRecipients] = useState([]);
  const [currentInput, setCurrentInput] = useState('');
  const [currentBccInput, setCurrentBccInput] = useState('');
  const quillRef = useRef(null);

  useEffect(() => {
    setEditedMessage(prev => ({
      ...message,
      type: message.type || 'email'
    }));
    setFiles(message.attachments || []);
    setViewMode(mode === 'view');
    setRecipients(message.recipients ? message.recipients.split(',').map(r => r.trim()) : []);
    setBccRecipients(message.bcc ? message.bcc.split(',').map(r => r.trim()) : []);
  }, [message, mode]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedMessage((prev) => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (content) => {
    setEditedMessage((prev) => ({ ...prev, content }));
  };

  const handleSave = () => {
    const updatedMessage = {
      ...editedMessage,
      attachments: files,
      recipients: recipients.join(','),
      bcc: bccRecipients.join(',')
    };
    onSave(updatedMessage);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const toggleViewMode = () => {
    setViewMode(!viewMode);
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPhoneNumber = (number) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(number);
  };

  const handleKeyDown = (e, isRecipientsInput) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const input = isRecipientsInput ? currentInput : currentBccInput;
      const trimmedInput = input.trim();
      if (editedMessage.type === 'email' && isValidEmail(trimmedInput)) {
        if (isRecipientsInput) {
          setRecipients((prev) => [...prev, trimmedInput]);
          setCurrentInput('');
        } else {
          setBccRecipients((prev) => [...prev, trimmedInput]);
          setCurrentBccInput('');
        }
      } else if (editedMessage.type === 'sms' && isValidPhoneNumber(trimmedInput)) {
        setRecipients((prev) => [...prev, trimmedInput]);
        setCurrentInput('');
      }
    }
  };

  const removeRecipient = (index, isBcc) => {
    if (isBcc) {
      setBccRecipients((prev) => prev.filter((_, i) => i !== index));
    } else {
      setRecipients((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

// ... continued from part 1

return (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
    <div className="relative bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto p-6 custom-scrollbar">
      <div className="flex justify-between items-center pb-3 border-b">
        <h2 className="text-2xl font-semibold text-gray-700">
          {viewMode ? 'View Message' : 'Edit Message'}
        </h2>
        <div className="flex space-x-2">
          <button onClick={toggleViewMode} className="text-gray-400 hover:text-gray-500">
            {viewMode ? <PencilIcon className="h-6 w-6" /> : <EyeIcon className="h-6 w-6" />}
          </button>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
      </div>

      <div className="mt-4 space-y-4">
        {!viewMode && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Message Type</label>
            <div className="flex space-x-2">
              <button
                onClick={() => setEditedMessage(prev => ({ ...prev, type: 'email' }))}
                className={`px-4 py-2 rounded-md ${
                  editedMessage.type === 'email' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'
                }`}
              >
                Email
              </button>
              <button
                onClick={() => setEditedMessage(prev => ({ ...prev, type: 'sms' }))}
                className={`px-4 py-2 rounded-md ${
                  editedMessage.type === 'sms' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'
                }`}
              >
                SMS
              </button>
            </div>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
          {viewMode ? (
            <p className="text-gray-900">{editedMessage.title}</p>
          ) : (
            <input
              type="text"
              name="title"
              value={editedMessage.title}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          )}
        </div>

        {editedMessage.type === 'email' && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Send from</label>
            {viewMode ? (
              <p className="text-gray-900">{editedMessage.sender}</p>
            ) : (
              <>
                <select
                  name="sender"
                  value={editedMessage.sender}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  {userEmails.map((email) => (
                    <option key={email} value={email}>{email}</option>
                  ))}
                  <option value="myecho">Secure Echo Email</option>
                </select>
                {editedMessage.sender === 'myecho' && (
                  <div className="mt-1 text-green-600 flex items-center">
                    <CheckCircleIcon className="h-4 w-4 mr-1" />
                    <span className="text-sm">Verified secure email</span>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {editedMessage.type === 'email' ? 'Recipients' : 'Phone Number(s)'}
          </label>
          {viewMode ? (
            <p className="text-gray-900">{recipients.join(', ')}</p>
          ) : (
            <div>
              <div className="flex flex-wrap gap-2 mb-2">
                {recipients.map((recipient, index) => (
                  <div key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm flex items-center">
                    {recipient}
                    <button onClick={() => removeRecipient(index)} className="ml-2 text-blue-600 hover:text-blue-800">
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="text"
                value={currentInput}
                onChange={(e) => setCurrentInput(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, true)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder={editedMessage.type === 'email' 
                  ? "Enter email addresses and press Enter or comma to add"
                  : "Enter phone numbers with country code (e.g., +1234567890)"
                }
              />
            </div>
          )}
        </div>

        {editedMessage.type === 'email' && (
          <div>
            <button 
              onClick={() => setShowBcc(!showBcc)} 
              className="text-sm text-blue-600 hover:text-blue-800"
            >
              {showBcc ? 'Hide BCC' : 'Show BCC'}
            </button>
            {showBcc && (
              <div className="mt-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">BCC</label>
                {viewMode ? (
                  <p className="text-gray-900">{bccRecipients.join(', ')}</p>
                ) : (
                  <div>
                    <div className="flex flex-wrap gap-2 mb-2">
                      {bccRecipients.map((recipient, index) => (
                        <div key={index} className="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-sm flex items-center">
                          {recipient}
                          <button onClick={() => removeRecipient(index, true)} className="ml-2 text-gray-600 hover:text-gray-800">
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>
                    <input
                      type="text"
                      value={currentBccInput}
                      onChange={(e) => setCurrentBccInput(e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, false)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter BCC email addresses and press Enter or comma to add"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Content</label>
          {viewMode ? (
            <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: editedMessage.content }} />
          ) : (
            editedMessage.type === 'email' ? (
              <div ref={quillRef}>
                <ReactQuill
                  value={editedMessage.content}
                  onChange={handleContentChange}
                  modules={quillModules}
                  className="h-64 border border-gray-300 rounded-md"
                />
              </div>
            ) : (
              <textarea
                name="content"
                value={editedMessage.content}
                onChange={handleInputChange}
                rows="4"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                maxLength="160"
              />
            )
          )}
        </div>

        {editedMessage.type === 'email' && !viewMode && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Attachments</label>
            <div
              {...getRootProps()}
              className="border-2 border-dashed border-gray-300 rounded-md p-4 text-center hover:border-blue-500 transition duration-150 ease-in-out cursor-pointer"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="text-blue-500">Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
            {files.length > 0 && (
              <ul className="mt-2 space-y-1">
                {files.map((file, index) => (
                  <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                    <div className="flex items-center">
                      <PaperClipIcon className="h-5 w-5 text-gray-500 mr-2" />
                      <span className="text-sm text-gray-700">{file.name}</span>
                    </div>
                    <button
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>

      {!viewMode && (
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      )}
    </div>
  </div>
);
};

export default MessageEditor;