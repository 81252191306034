// src/pages/dashboard/DashboardHome.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { EnvelopeIcon, BoltIcon, LinkIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/AuthContext';

const DashboardCard = ({ title, icon: Icon, description, link, notification }) => (
  <Link to={link} className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow duration-300">
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-shrink-0 bg-blue-500 rounded-md p-3">
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <div className="ml-5 w-0 flex-1">
          <dl>
            <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
            <dd>
              <div className="text-lg font-medium text-gray-900">{description}</div>
            </dd>
          </dl>
        </div>
        {notification && (
          <div className="ml-4 flex-shrink-0">
            <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  </Link>
);

const DashboardHome = () => {
  const { user } = useAuth();
  // These should be fetched from your backend in a real application
  const messagesCount = 0;
  const triggersCount = 0;
  const connectionsCount = 0;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Welcome back, {user?.name}!</h1>
      
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-8">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-blue-700">
              Complete your setup to ensure your digital legacy is managed according to your wishes.
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <DashboardCard
          title="Messages"
          icon={EnvelopeIcon}
          description={`${messagesCount} messages created`}
          link="/dashboard/messages"
          notification={messagesCount === 0}
        />
        <DashboardCard
          title="Triggers"
          icon={BoltIcon}
          description={`${triggersCount} triggers set`}
          link="/dashboard/triggers"
          notification={triggersCount === 0}
        />
        <DashboardCard
          title="Connections"
          icon={LinkIcon}
          description={`${connectionsCount} accounts connected`}
          link="/dashboard/connections"
          notification={connectionsCount === 0}
        />
      </div>

      <div className="mt-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Next Steps</h2>
        <ul className="divide-y divide-gray-200">
          {messagesCount === 0 && (
            <li className="py-4">
              <Link to="/dashboard/messages" className="text-blue-600 hover:text-blue-800">Create your first message</Link>
            </li>
          )}
          {triggersCount === 0 && (
            <li className="py-4">
              <Link to="/dashboard/triggers" className="text-blue-600 hover:text-blue-800">Set up your triggers</Link>
            </li>
          )}
          {connectionsCount === 0 && (
            <li className="py-4">
              <Link to="/dashboard/connections" className="text-blue-600 hover:text-blue-800">Connect your first account</Link>
            </li>
          )}
          {messagesCount > 0 && triggersCount > 0 && connectionsCount > 0 && (
            <li className="py-4">
              <span className="text-green-600">Great job! Your digital legacy is set up.</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DashboardHome;