// src/components/dashboard/triggers/TriggerCard.jsx
import React, { useState } from 'react';
import { InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import InfoModal from '../../common/InfoModal';

const TriggerCard = ({ type, settings, onSave }) => {
  const [localSettings, setLocalSettings] = useState(settings);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalSettings(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(type, localSettings);
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  const handleEmailInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const email = currentEmail.trim();
      if (email && isValidEmail(email)) {
        setLocalSettings(prev => ({
          ...prev,
          trustedContacts: [...prev.trustedContacts, email]
        }));
        setCurrentEmail('');
      }
    }
  };

  const removeEmail = (emailToRemove) => {
    setLocalSettings(prev => ({
      ...prev,
      trustedContacts: prev.trustedContacts.filter(email => email !== emailToRemove)
    }));
  };

  const getInfoContent = () => {
    if (type === 'Recurring Check-in') {
      return (
        <>
          <p>This trigger sends periodic check-ins based on your chosen frequency. If you don't respond within the specified grace period, it initiates the process to release your messages.</p>
          <p className="mt-2">How it works:</p>
          <ol className="list-decimal list-inside mt-2">
            <li>You'll receive a check-in notification via your chosen method (email or SMS).</li>
            <li>If you don't respond within the grace period, a final verification will be sent.</li>
            <li>If there's no response to the final verification, your messages will be released.</li>
          </ol>
          <p className="mt-2">Ensure your contact information is always up to date to prevent accidental triggers.</p>
        </>
      );
    } else if (type === 'Trusted Consensus') {
      return (
        <>
          <p>This trigger activates when a specified number of your trusted contacts confirm your passing. It then initiates a final check before releasing your messages.</p>
          <p className="mt-2">How it works:</p>
          <ol className="list-decimal list-inside mt-2">
            <li>Your trusted contacts inform us of your passing.</li>
            <li>When the required number of confirmations is reached, we'll send a final verification to your primary contact method.</li>
            <li>If there's no response to the final verification within the grace period, your messages will be released.</li>
          </ol>
          <p className="mt-2">This method provides an additional layer of verification to prevent false triggers.</p>
        </>
      );
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{type}</h3>
        <button onClick={() => setIsInfoModalOpen(true)} className="text-blue-600 hover:text-blue-800">
          <InformationCircleIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
        {type === 'Recurring Check-in' && (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Check-in Frequency</label>
              <select
                name="frequency"
                value={localSettings.frequency}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Notification Method</label>
              <select
                name="notificationMethod"
                value={localSettings.notificationMethod}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="email">Email</option>
                <option value="sms">SMS</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Grace Period for Missed Check-ins</label>
              <select
                name="gracePeriod"
                value={localSettings.gracePeriod}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="1day">1 Day</option>
                <option value="1week">1 Week</option>
                <option value="1month">1 Month</option>
              </select>
            </div>
          </>
        )}
        {type === 'Trusted Consensus' && (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Number of Confirmations Required</label>
              <input
                type="number"
                name="requiredConfirmations"
                value={localSettings.requiredConfirmations}
                onChange={handleInputChange}
                min="1"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Trusted Contacts</label>
              <div className="mt-1 flex flex-wrap gap-2">
                {localSettings.trustedContacts.map((email, index) => (
                  <div key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm flex items-center">
                    {email}
                    <button onClick={() => removeEmail(email)} className="ml-2 text-blue-600 hover:text-blue-800">
                      <XCircleIcon className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="email"
                value={currentEmail}
                onChange={handleEmailInputChange}
                onKeyDown={handleEmailInputKeyDown}
                className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Enter email and press Enter"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Final Verification Grace Period</label>
              <select
                name="finalVerificationPeriod"
                value={localSettings.finalVerificationPeriod}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="1day">1 Day</option>
                <option value="3days">3 Days</option>
                <option value="1week">1 Week</option>
              </select>
            </div>
          </>
        )}
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6">
        <button
          onClick={handleSave}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save Changes
        </button>
      </div>
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        title={type}
        content={getInfoContent()}
      />
    </div>
  );
};

export default TriggerCard;