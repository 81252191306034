import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ConnectionsPage = () => {
  const handleConnect = (provider) => {
    // Here you would implement the logic to connect to the provider (e.g., Google, Outlook)
    // This might involve:
    // - Opening a new window or redirecting to the provider's OAuth page
    // - Handling the OAuth callback and storing the access token
    console.log(`Connect with ${provider}`);
  };

  const handleDisconnect = (provider, account) => {
    // Here you would implement the logic to disconnect the account
    console.log(`Disconnect ${account} from ${provider}`);
  };

  // Sample connected accounts (replace with your actual logic)
  const connectedAccounts = {
    Gmail: ['user123@gmail.com'],
    Outlook: ['john.doe@outlook.com', 'jane.smith@outlook.com'], 
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Connections</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Gmail */}
        <div className="bg-white shadow-md rounded-lg p-6 relative">
          <div className="flex items-center mb-4"> 
            <img 
              src="https://img.icons8.com/color/48/gmail-new.png" 
              alt="Gmail" 
              className="h-8 w-8 mr-3" 
            />
            <h2 className="text-lg font-semibold text-gray-900">Gmail</h2> 
          </div>

          <button
            onClick={() => handleConnect('Gmail')} 
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-4" 
          >
            Connect Gmail Account
          </button>

          <span className={`absolute top-6 right-6 px-2 py-1 text-xs font-semibold rounded-full 
            ${connectedAccounts.Gmail.length > 0 ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
            {connectedAccounts.Gmail.length > 0 ? 'Connected' : 'Not Connected'}
          </span>

          <ul>
            {connectedAccounts.Gmail.map((account) => (
              <li key={account} className="flex items-center justify-between">
                <span>{account}</span>
                <button onClick={() => handleDisconnect('Gmail', account)} className="text-red-500 hover:text-red-700">
                  <XMarkIcon className="h-4 w-4" /> 
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Outlook */}
        <div className="bg-white shadow-md rounded-lg p-6 relative">
          <div className="flex items-center mb-4">
            <img 
              src="https://img.icons8.com/color/48/ms-outlook.png" 
              alt="Outlook" 
              className="h-8 w-8 mr-3" 
            />
            <h2 className="text-lg font-semibold text-gray-900">Outlook</h2> 
          </div>

          <button
            onClick={() => handleConnect('Outlook')} 
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-4" 
          >
            Connect Outlook Account
          </button>

          <span className={`absolute top-6 right-6 px-2 py-1 text-xs font-semibold rounded-full 
            ${connectedAccounts.Outlook.length > 0 ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
            {connectedAccounts.Outlook.length > 0 ? 'Connected' : 'Not Connected'}
          </span>

          <ul>
            {connectedAccounts.Outlook.map((account) => (
              <li key={account} className="flex items-center justify-between">
                <span>{account}</span>
                <button onClick={() => handleDisconnect('Outlook', account)} className="text-red-500 hover:text-red-700">
                  <XMarkIcon className="h-4 w-4" /> 
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Add more connection providers here */}
      </div>
    </div>
  );
};

export default ConnectionsPage;