import React from 'react';
import { motion } from 'framer-motion';
import { CheckIcon } from '@heroicons/react/24/outline';

const pricingPlans = [
  {
    name: "Basic",
    price: "$4.99",
    features: [
      "5 scheduled messages",
      "Email and text message support",
      "1 GB secure storage",
      "Basic triggers"
    ],
    freeTrial: true
  },
  {
    name: "Pro",
    price: "$9.99",
    features: [
      "Unlimited scheduled messages",
      "Email, text, and social media support",
      "5 GB secure storage",
      "Advanced triggers",
      "Priority support"
    ],
    freeTrial: true
  },
  {
    name: "Enterprise",
    price: "Custom",
    features: [
      "All Pro features",
      "Unlimited secure storage",
      "Custom integrations",
      "Dedicated account manager",
      "On-premise deployment option"
    ],
    freeTrial: false
  }
];

const PricingCard = ({ name, price, features, isPopular, freeTrial }) => (
  <motion.div
    className={`bg-white rounded-lg shadow-lg overflow-hidden ${isPopular ? 'border-2 border-blue-500' : ''}`}
    whileHover={{ y: -10 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    {isPopular && (
      <div className="bg-blue-500 text-white text-center py-2 font-semibold">
        Most Popular
      </div>
    )}
    <div className="p-6">
      <h3 className="text-2xl font-semibold text-gray-900 mb-4">{name}</h3>
      <div className="text-4xl font-bold mb-6">{price}<span className="text-gray-500 text-lg font-normal">/month</span></div>
      {freeTrial && (
        <p className="text-green-600 font-semibold mb-6">Includes 14-day free trial</p>
      )}
      <ul className="space-y-4 mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <button className="w-full bg-blue-600 text-white rounded-md py-2 font-semibold hover:bg-blue-700 transition duration-300">
        {freeTrial ? "Start Free Trial" : "Contact Sales"}
      </button>
    </div>
  </motion.div>
);

const Pricing = () => (
  <section id="pricing" className="py-20 bg-gray-50">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Simple, Transparent Pricing
        </h2>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Choose the plan that's right for you
        </p>
      </div>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
        {pricingPlans.map((plan, index) => (
          <PricingCard key={index} {...plan} isPopular={index === 1} />
        ))}
      </div>
    </div>
  </section>
);

export default Pricing;