import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import MessageList from '../../components/dashboard/messages/MessageList';
import MessageEditor from '../../components/dashboard/messages/MessageEditor';

const MessagesPage = () => {
  const [messages, setMessages] = useState([
    { 
      id: 1, 
      title: "Final Goodbye", 
      recipients: "family@example.com", 
      bcc: "", 
      sender: "user@example.com", 
      trigger: "Upon passing", 
      status: "Pending", 
      content: "My final message to my loved ones.", 
      type: "text" 
    },
    { 
      id: 2, 
      title: "Business Instructions", 
      recipients: "partner@example.com", 
      bcc: "", 
      sender: "work@example.com", 
      trigger: "Upon passing", 
      status: "Pending", 
      content: "Important information about our business.", 
      type: "document" 
    },
  ]);

  const [editorOpen, setEditorOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [editorMode, setEditorMode] = useState('view');

  // This would typically come from your user's profile or a backend API
  const userEmails = ['user@example.com', 'work@example.com'];

  const handleView = (message) => {
    setSelectedMessage(message);
    setEditorMode('view');
    setEditorOpen(true);
  };

  const handleEdit = (message) => {
    setSelectedMessage(message);
    setEditorMode('edit');
    setEditorOpen(true);
  };

  const handleDelete = (message) => {
    setMessages(messages.filter(m => m.id !== message.id));
  };

  const handleCreate = () => {
    setSelectedMessage({
      id: Date.now(),
      title: "",
      recipients: "",
      bcc: "",
      sender: "",
      trigger: "Upon passing",
      status: "Pending",
      content: "",
      type: "text"
    });
    setEditorMode('edit');
    setEditorOpen(true);
  };

  const handleSave = (editedMessage) => {
    if (editorMode === 'edit') {
      setMessages(messages.map(m => m.id === editedMessage.id ? editedMessage : m));
    } else if (editorMode === 'create') {
      setMessages([...messages, editedMessage]);
    }
    setEditorOpen(false);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 relative">

      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-900">Messages</h1>
        <button 
          onClick={handleCreate}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          New Message
        </button>
      </div>

      <MessageList
        messages={messages}
        onView={handleView}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      {editorOpen && (
        <div className="absolute inset-0 flex items-center justify-center z-50"> 
          <MessageEditor
            message={selectedMessage}
            mode={editorMode}
            onSave={handleSave}
            onClose={() => setEditorOpen(false)}
            userEmails={userEmails}
          />
        </div>
      )}
    </div>
  );
};

export default MessagesPage;