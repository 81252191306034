import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import { useAuth } from '../../contexts/AuthContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SettingsPage = () => {
  const [emailNotifications, setEmailNotifications] = useState(true)
  const [smsNotifications, setSmsNotifications] = useState(false)

  const { user } = useAuth();

  return (
    <div className="max-w-3xl mx-auto">
       <h1 className="text-3xl font-bold text-gray-900 mb-6">Settings</h1>
      
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Update your account details here.</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
          <form className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Full Name</label>
              <input type="text" name="name" id="name" value={user?.name} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="John Doe" />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
              <input type="email" name="email" disabled value={user?.email} id="email" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="you@example.com" />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
              <input type="password" name="password" id="password" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
            </div>
          </form>
        </div>
      </div>

      <div className="mt-10 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">Notifications</h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Decide which communications you'd like to receive.</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
          <div className="space-y-6">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Email notifications
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Receive email notifications about your account activity.
                </Switch.Description>
              </span>
              <Switch
                checked={emailNotifications}
                onChange={setEmailNotifications}
                className={classNames(
                  emailNotifications ? 'bg-blue-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    emailNotifications ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  SMS notifications
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Receive text message notifications about your account activity.
                </Switch.Description>
              </span>
              <Switch
                checked={smsNotifications}
                onChange={setSmsNotifications}
                className={classNames(
                  smsNotifications ? 'bg-blue-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    smsNotifications ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end">
        <button type="button" className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;