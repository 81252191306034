import React, { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const personalSwitches = [
  { text: "Send anniversary message to spouse", completed: true },
  { text: "Transfer digital assets to heir", completed: true },
  { text: "Post farewell on social media", completed: false },
  { text: "Donate to favorite charity", completed: true },
];

const businessSwitches = [
  { text: "Send encrypted codes to successor", completed: true },
  { text: "Transfer business ownership documents", completed: true },
  { text: "Notify key clients of succession plan", completed: false },
  { text: "Release final statement to employees", completed: true },
];

const SwitchList = () => {
  const [activeTab, setActiveTab] = useState('personal');

  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md border border-gray-100">
      <h3 className="text-2xl font-semibold mb-4 text-gray-800">My Digital Legacy Plan</h3>
      <div className="flex mb-4">
        <button
          className={`flex-1 py-2 px-4 text-sm font-medium rounded-l-lg ${
            activeTab === 'personal'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          onClick={() => setActiveTab('personal')}
        >
          Personal
        </button>
        <button
          className={`flex-1 py-2 px-4 text-sm font-medium rounded-r-lg ${
            activeTab === 'business'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          onClick={() => setActiveTab('business')}
        >
          Business
        </button>
      </div>
      <ul className="space-y-3">
        {(activeTab === 'personal' ? personalSwitches : businessSwitches).map((item, index) => (
          <li key={index} className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-50 transition-colors duration-150">
            <CheckCircleIcon 
              className={`flex-shrink-0 h-6 w-6 ${item.completed ? 'text-blue-500' : 'text-gray-300'}`} 
            />
            <span className={`text-sm ${item.completed ? 'text-gray-800 font-medium' : 'text-gray-500'}`}>
              {item.text}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SwitchList;