import React from 'react';
import { PencilIcon, TrashIcon, EyeIcon } from '@heroicons/react/24/outline';

const MessageCard = ({ message, onView, onEdit, onDelete }) => (
    <div className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden rounded-lg">
      <div className="px-6 py-4 flex justify-between items-center">
        <div>
          <h3 className="text-lg font-semibold text-gray-900">{message.title}</h3>
          <p className="mt-1 text-sm text-gray-600">To: {message.recipients}</p>
          <p className="mt-1 text-sm text-gray-500">Trigger: {message.trigger}</p>
        </div>
        <div className="flex flex-col items-end">
          <span className={`px-2 py-1 text-xs font-semibold rounded-full ${
            message.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'
          }`}>
            {message.status}
          </span>
          <div className="flex space-x-2 mt-2">
            <button onClick={() => onView(message)} className="text-blue-600 hover:text-blue-800">
              <EyeIcon className="h-5 w-5" />
            </button>
            <button onClick={() => onEdit(message)} className="text-green-600 hover:text-green-800">
              <PencilIcon className="h-5 w-5" />
            </button>
            <button onClick={() => onDelete(message)} className="text-red-600 hover:text-red-800">
              <TrashIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
export default MessageCard;