import React from 'react';
import { motion } from 'framer-motion';
import { HeartIcon, BriefcaseIcon, GlobeAltIcon } from '@heroicons/react/24/outline';

const UseCaseCard = ({ icon: Icon, title, description }) => (
  <motion.div
    className="bg-gray-50 p-6 rounded-lg shadow-md"
    whileHover={{ y: -5 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex items-center mb-4">
      <div className="flex-shrink-0 mr-4">
        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-100">
          <Icon className="w-6 h-6 text-blue-600" />
        </div>
      </div>
      <h3 className="text-xl font-semibold">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const UseCases = () => (
  <section className="py-20 bg-white">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          How People Use MyEcho
        </h2>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
          Discover the various ways MyEcho can help secure your digital legacy
        </p>
      </div>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
        <UseCaseCard
          icon={HeartIcon}
          title="Family Messages"
          description="Leave heartfelt messages for your loved ones, sharing your thoughts, memories, and wishes for their future."
        />
        <UseCaseCard
          icon={BriefcaseIcon}
          title="Business Continuity"
          description="Ensure critical business information and instructions are passed on to the right people at the right time."
        />
        <UseCaseCard
          icon={GlobeAltIcon}
          title="Digital Asset Management"
          description="Securely manage and transfer access to your digital assets, including social media accounts and online subscriptions."
        />
      </div>
    </div>
  </section>
);

export default UseCases;